function update_model(makeref, modelref) {
  if (makeref.val() == 1) { // NO MAKE
    modelref.empty();
    var row = "<option selected=\"selected\" value=\"" + "1" + "\">" + "NO MODEL" + "</option>";
    $(row).appendTo(modelref);

  } else {
    if (makeref.val() == 2) { // UNKNOWN
      modelref.empty();
      var row = "<option selected=\"selected\" value=\"" + "2" + "\">" + "UNKNOWN" + "</option>";
      $(row).appendTo(modelref);

    } else { // get model for make
      modelref.empty();
      update_model_server(makeref, modelref)
    }
  }
}

function update_model_server(makeref, modelref) {
  $.ajax({
    url: "/models/find_by_make?make_id="+makeref.val(),
    type: 'GET'
  }).done(function(data) {

    modelref.empty();

    $.each(data, function(i, j) {
     row = "<option value=\"" + j.id + "\">" + j.name + "</option>";
     $(row).appendTo(modelref);
    });
  });
} ;

/* in page
<script>
  $(document).ready(function(){
    makeref = $('#entry_make_id') ;
    modelref = $('#entry_model_id') ;
    update_model(makeref,modelref) ;
    makeref.change(update_model(makeref,modelref)) ;
  }) ;
</script>
*/
